import {createStore} from 'vuex';
import {NotarySteps} from '@/utils/NotarySteps';

export interface State {
  actualStep: NotarySteps.UPLOAD;
  documentData: any;
  blExplorerHost: string;
  isSampleFileSelected: boolean,
  notarizeData: { docId: number | null; nodeIp: string; metaKeys?: { key: string; placeholder: string }[] };
  fileList: Array<any>;
}

export default createStore<State>({
  state: {
    actualStep: 0,
    documentData: {},
    blExplorerHost: "",
    isSampleFileSelected: false,
    notarizeData: {
      docId: null,
      nodeIp: "",
      metaKeys: [
        { key:"", placeholder: "Order date: May 30, 2024" },
        { key:"", placeholder: "Order: 3,000 packages of wet wipes" },
        { key:"", placeholder: "Delivery services: ABC, 123" },
        { key:"", placeholder: "Cost per unit: $5.00/package" },
        { key:"", placeholder: "Discount Code: CARPOOL" }
      ]
    },
    fileList: [],
  },
  getters: {
    getActualStep: (state) => state.actualStep,
    getDocumentData: (state) => state.documentData,
    getNotarizeData: (state) => state.notarizeData,
    getBlExplorerHost: (state) => state.blExplorerHost,
    getFileList: (state) => state.fileList,
    getIsSampleFileSelected: (state) => state.isSampleFileSelected,
  },
  mutations: {
    setStep(state, step) {
      state.actualStep = step
    },
    setFileList(state, list) {
      state.fileList = [...list];
    },
    setIsSampleFileSelected(state, isSelected) {
      state.isSampleFileSelected = isSelected
    },
    setNotarizeData(state, data) {
      state.notarizeData = data
    },
    setBlExplorerHost(state, data) {
      state.blExplorerHost = data.url
    },
    setDocumentData(state, data) {
      state.documentData[data.key] = data.value
    },
    resetDocumentData(state) {
      state.notarizeData = {
        docId: null,
        nodeIp: "",
        metaKeys: [
          { key:"", placeholder: "Order date: May 30, 2024" },
          { key:"", placeholder: "Order: 3,000 packages of wet wipes" },
          { key:"", placeholder: "Delivery services: ABC, 123" },
          { key:"", placeholder: "Cost per unit: $5.00/package" },
          { key:"", placeholder: "Discount Code: CARPOOL" }
        ]
      }
      state.documentData = {}
    },
    resetDocument(state) {
      state.documentData = {}
    },
  },
  actions: {
    //
  },
  modules: {
    //
  }
})
