import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Wrapper from '@/views/Layouts/LayoutWrapper.vue'
import store from '../store'
import { NotarySteps } from "@/utils/NotarySteps";

function load(path: string) {
  // '@' is aliased to src/components
  return () => import(`@/views/${path}.vue`)
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: 'auth',
        name: 'auth',
        component: load('Auth/Auth'),
        meta: {
          protected: false,
          layout: 'guest'
        }
      },
      {
        path: '/',
        name: 'home',
        component: load('HomeView'),
        meta: {
          protected: true,
          layout: 'auth'
        }
      },
      {
        path: '/terms-and-conditions',
        name: 'terms',
        component: load('TermsAndConditionsView'),
        meta: {
          protected: true,
          layout: 'auth'
        }
      },
      {
        path: '/templates',
        name: 'templates',
        component: load('HomeView'),
        meta: {
          protected: true,
          layout: 'auth'
        }
      },
      {
        path: '/block/:id',
        name: 'block',
        component: load('BlockComponent'),
        meta: {
          protected: true,
          layout: 'auth'
        }
      },
      {
        path: '/upload',
        name: 'upload',
        component: load('UploadComponent'),
        meta: {
          protected: true,
          layout: 'auth'
        },
        beforeEnter: (to, from, next) => {
          store.state.actualStep = NotarySteps.UPLOAD
          store.state.documentData = {}
          store.state.notarizeData = {
            docId: null,
            nodeIp: "",
            metaKeys: [
              { key:"", placeholder: "Order date: May 30, 2024" },
              { key:"", placeholder: "Order: 3,000 packages of wet wipes" },
              { key:"", placeholder: "Delivery services: ABC, 123" },
              { key:"", placeholder: "Cost per unit: $5.00/package" },
              { key:"", placeholder: "Discount Code: CARPOOL" }
            ]
          }
          next()
        }
      },
      {
        path: '/upload/:id/notarize/:notarizeId',
        name: 'feedback',
        component: load('FeedbackComponent'),
        meta: {
          protected: true,
          layout: 'auth'
        }
      },
      // {
      //   path: '/search',
      //   name: 'search',
      //   component: load('Search'),
      //   meta: {
      //     protected: true,
      //     layout: 'auth'
      //   }
      // },
      {
        path: '/faqs',
        name: 'faqs',
        component: load('FaqComponent'),
        meta: {
          protected: true,
          layout: 'auth'
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: load('PageNotFound'),
        meta: {
          notFound: true,
          protected: false,
          layout: 'guest'
        }
      }
    ]
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // return desired position
    return { top: 0 }
  }
})
export default router




