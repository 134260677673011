<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import api from "./helpers/api";

export default {
  name: 'App',
  data() {
    return {
      //
    }
  },
  created() {
    this.getBlockExplorerHost();
  },
  methods: {
    getBlockExplorerHost() {
      api.getBlockExplorerHost()
      .then(res => {
        const data = res.data
        this.$store.commit('setBlExplorerHost', { url: data.url })
      })
    }
  }
}
</script>

<style>
* {
  font-family: 'Jost-Regular', sans-serif;
}

div.header-bar-left img {
  width: auto;
  /*max-width: 190px;*/
  height: auto;
  position: relative;
  bottom: -10px;
  transform: translate(0px, 10px);
}
div#nav a {
  margin-right: 1.5vw;
  font-size: 20px;
  color: #231f20;
}
#nav a.router-link-exact-active {
  color: #479997;
}
</style>



