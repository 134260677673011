<template>
  <h1 class="text-left mt-5" :class="classes">
    {{ text }}
  </h1>
</template>

<script>
export default {
  name: 'HeadingComponent',
  props: {
    text: {
      required: true,
      type: String
    },
    classes: {
      required: false,
      type: String
    }
  }
}
</script>
