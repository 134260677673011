<template>
  <div id="main" class="main_container">
    <div class="user_dashboard">
      <Header />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '../../components/HeaderComponent'
import Footer from '../../components/FooterComponent'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style>
.link {
  padding-top: 20px;
}
#main {
  overflow-y: auto;
  padding-bottom: 100px;
}
a {
  text-decoration: underline;
}
</style>
