<template>
  <div>
    <slot />
    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/FooterComponent'

export default {
  components: {
    Footer
  }
}
</script>
