<template>
 <header class="bg-gray-light">
   <nav class="border-b-4 border-white pt-10 pb-2.5 px-[20px] md:px-0">
     <div class="lg:w-10/12 sm:w-10/12 xs:w-10/12 pb-1 m-auto mx-auto md:w-11/12">
       <div class="md:flex md:items-center md:justify-between">
         <div class="md:block hidden flex mx-0 space-x-7">
           <div>
             <!-- Website Logo -->
             <router-link :to="{name: 'home'}" class="flex items-center pb-1.5 pt-4 router-link-active">
               <img src="../../public/img/geeq-sign.png" alt="Logo" class="logo h-1/2 mr-2">
             </router-link>
           </div>
         </div>
         <div>
           <!-- Mobile menu button -->
           <div class="md:hidden flex items-center justify-between">
             <div>
               <!-- Website Logo -->
               <router-link :to="{name: 'home'}" class="flex items-center pb-1.5 pt-4 router-link-active">
                 <img src="../../public/img/geeq-sign.png" alt="Logo" class="logo h-1/2 mr-2">
               </router-link>
             </div>
             <input id="menu-toggle" type="checkbox" v-model="showMenu" />

             <label class='menu-button-container p-5 relative' for="menu-toggle">

               <div class='menu-button'></div>
             </label>
           </div>
           <div class="flex justify-end">
             <!-- Primary Navbar items -->
             <ul
                  class="hidden items-center space-x-1 md:flex py-0.5 navigation list-none"
             >
               <li>
                 <router-link
                   to="/upload"
                   class="r-link py-4 px-2 text-blue-dark transition duration-300 text-xl font-light"
                   @click.prevent="prepareUploadPage"
                 >
                   UPLOAD
                 </router-link>
               </li>
               <li>
                 <a
                   target="_blank"
                   :href="blExplorerHost"
                   class="r-link py-4 px-2 text-blue-dark transition duration-300 text-xl font-light"
                 >
                   SEARCH
                 </a>
               </li>
               <li>
                 <router-link
                   to="/faqs"
                   class="r-link py-4 px-2 text-blue-dark transition duration-300 text-xl font-light"
                 >
                   FAQs
                 </router-link>
               </li>
               <button-item
                 @clicked="goToGeeqIo"
                 text="Learn more"
                 :classes="`uppercase btn-get-in-touch px-[30px] py-[7px] !ml-[50px] block border border-[#D66339] hover:border-blue-dark inline-block text-left text-[20px] text-white bg-[#3D94C3] hover:bg-blue-dark`"
               />
             </ul>

             <!-- mobile menu -->
             <ul class="md:hidden block mobile-menu" :class="{'toggle-menu-height': showMenu}">
               <li>
                 <router-link
                   to="/upload"
                   @click="handleRouteClick(true)"
                   class="inline-block text-sm py-2"
                 >
                   UPLOAD
                 </router-link>
               </li>
               <li>
                 <a
                   target="_blank"
                   :href="blExplorerHost"
                   @click="handleRouteClick(false)"
                   class="inline-block text-sm py-2"
                 >
                   SEARCH
                 </a>
               </li>
               <li>
                 <router-link
                   to="/faqs"
                   @click="handleRouteClick(false)"
                   class="inline-block text-sm py-2"
                 >
                   FAQs
                 </router-link>
               </li>
               <button-item
                 @clicked="goToGeeqIo"
                 @click="handleRouteClick(false)"
                 text="Learn more"
                 :classes="`uppercase px-[20px] py-[10px] !my-[20px] border border-[#D66339] hover:border-blue-dark inline-block text-left text-[18px] text-white bg-blue-light hover:bg-blue-dark`"
               />
             </ul>
           </div>
         </div>
       </div>
     </div>
   </nav>
   <hr class="border-b-8 border-gray-light" />
 </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { NotarySteps } from "../utils/NotarySteps";
import ButtonItem from "@/components/ButtonItem";

export default {
  name: 'HeaderComponent',
  components: { ButtonItem },
  data: () => ({
    showMenu: false,
    NotarySteps: NotarySteps
  }),
  computed: {
    ...mapGetters({
      blExplorerHost: 'getBlExplorerHost'
    }),
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    handleRouteClick(isUploadRoute) {
      if (isUploadRoute) {
        this.prepareUploadPage();
      }
      this.showMenu = false;
    },
    goToGeeqIo() {
      window.open("https://geeq.io/geeq-data", "_blank");
    },
    getLinkToBlockExplorer() {
      return process.env.VUE_APP_EXPLORER_URL
    },
    changeStep(step) {
      this.$store.commit('setStep', step)
    },
    prepareUploadPage() {
      this.$store.commit('resetDocumentData')
      this.changeStep(this.NotarySteps.UPLOAD)
      this.$router.push("/upload")
    },
    getRouteParams(route) {
      const name = route.name.toLowerCase();
      if (route.params) {
        const params = {};
        route.params.forEach((p) => {
          params[p] = this.$route.params[p]
        })
        return { name: name, params: params}
      }
      return { name: name }
    }
  }
}
</script>

<style scoped>
.navigation a {
  display:inline-block;
  text-align:center;
}
.navigation a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
 .navigation .router-link-exact-active,
 .router-link-exact-active {
   color: #3D94C3;
 }
  .r-link:hover {
    color: #000;
  }
  .mobile-menu {
    transition: height 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

* {
  box-sizing: border-box;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #2E4064;
  position: absolute;
  height: 3px;
  width: 30px;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}
.mobile-menu {
  height: 0;
  overflow: hidden;
  width: 100%;
  text-align: right;
  transition: height 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.mobile-menu.toggle-menu-height {
  height: 100%;
  overflow: visible;
}
@media (max-width: 1080px) {
  .btn-get-in-touch {
    font-size: 18px;
    margin-left: 25px !important;
    padding: 15px 15px;
  }
}
@media (max-width: 850px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}
</style>
