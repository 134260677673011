<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AuthLayout from "@/views/Layouts/AuthLayout";
import GuestLayout from "@/views/Layouts/GuestLayout";
import MobileScreen from "@/components/MobileScreen";

export default {
  name: 'layout-wrapper',
  components: {
    AuthLayout,
    GuestLayout,
    MobileScreen
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data() {
    return {
      initial: true,
      window: {
        width: 0
      }
    }
  },
  computed: {
    route() {
      return this.$route;
    },
    layout: function() {
      return `${this.route.meta.layout}-layout`
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    isMobileDevice() {
      return this.isMobile()
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    }
  }
}
</script>

<style lang="css"></style>
