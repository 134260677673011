<template>
  <footer class="bg-gray-light">
    <div>
      <img src="../../public/img/border-footer.png" class="w-full" />
    </div>

    <div class="md:block flex justify-center border-t-4 border-white p-5">
      <div class="text-sm footer-container grid md:grid-cols-footer grid-cols-2">
        <div
          class="md:text-left text-right mx-1.5 my-1 footer-link"
        >
          <a
            href="https://geeq.io/"
            target="_blank"
            class="text-sm text-blue-dark"
          >
            {{ copyright }}
          </a>
        </div>
        <div class="md:text-center md:mr-0 mr-[26px] text-right mx-1.5 my-1 footer-link" style="flex-grow: 1">
          <a
            href="https://geeq.atlassian.net/servicedesk/customer/portal/1"
            target="_blank"
            class="text-sm text-red jost-medium"
          >
            Service Desk
          </a>
        </div>
        <div class="md:text-center text-left mx-1.5 my-1 footer-link" style="flex-grow: 1">
          <a
            href="https://geeq.io/privacy-policy/"
            target="_blank"
            class="text-sm text-blue jost-medium"
          >
            Privacy Policy
          </a>
        </div>
        <div class="md:text-center text-left mx-1.5 my-1 footer-link" style="flex-grow: 1">
          <router-link
            :to="{ name: 'terms'}"
            target="_blank"
            class="text-sm text-blue jost-medium"
          >
            Terms and Conditions
          </router-link>
        </div>
        <div
          class="md:text-right text-left mx-1.5 my-1 footer-link"
        >
          <span
            class="text-blue-dark"
          >
          <span class="text-sm text-blue jost-medium">
            Geeq Data
          </span> v{{ appVersion }}
        </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      copyright: `© Geeq.io ${process.env.VUE_APP_COPYRIGHT_YEAR}`,
    }
  }
}
</script>
<style scoped>
footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
@media(max-width:850px) {
  footer .footer-link:nth-of-type(1) {
    order: 4;
  }
  footer .footer-link:nth-of-type(2) {
    order: 2;
  }
  footer .footer-link:nth-of-type(3) {
    order: 1;
  }
  footer .footer-link:nth-of-type(4) {
    order: 3;
  }
  footer .footer-link:nth-of-type(5) {
    order: 5;
  }
}
</style>
