import axios from 'axios';

// create an axios instance with default options
const http = axios.create({ baseURL: window.location.origin });
// const http = axios.create({ baseURL: "http://localhost:3000" });
http.defaults.headers.common['authorization'] = 'adfsgehbaldkgmbalmkblDKFMBDLKFMBLKDMFB'
// const simulationHostName = process.env.VUE_APP_SIMULATOR_API_BASE_URL
// const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'

export function setHeader(key: string, value: string) {
    http.defaults.headers.common[key] = value
}

export default {
    uploadFile(data: any) {
        return http({
            method: "POST",
            url: '/api/document',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
    },
    getDocument(id: number) {
        return http.get(`/api/document/${id}`)
    },
    getKeys() {
        return http.get('/api/notarize/keys')
    },
    searchDocument(meta: string, hash: string, limit: number, skip: number) {
        return http.get(`/api/notarize?hash=${hash}&meta=${meta}&limit=${limit}&skip=${skip}`)
    },
    notarizeDocument(data: {docId: number; meta: Array<string>; nodeIp: string}) {
        return http.post('/api/notarize', data)
    },
    getActiveNodesIps() {
        return http.get('/api/notarize/getActiveNodeIps')
    },
    getBlockByNumber(number: number) {
      return http.get(`/api/blocks/${number}`)
    },
    getNotaryById(id: number | string) {
      return http.get(`/api/notarize/${id}`)
    },
    getBlockExplorerHost() {
      return http.get('/api/system')
    },
};
