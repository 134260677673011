<template>
  <button
      class="hover:bg-blue-light focus:bg-blue-dark hover:border-red focus:border-blue hover:text-white focus:text-white"
      :class="classes"
      type="button"
      @click="isClicked"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonItem",
  props: {
    text: {
      required: true,
      type: String
    },
    classes: {
      required: false,
      type: String
    }
  },
  methods: {
    isClicked() {
      this.$emit('clicked')
    }
  }
}
</script>