<template>
  <div class="mobile-screen-container flex items-center justify-center bg-gray-light w-full p-5" style="height: 100vh;">
      <heading
          text="not yet available for small screens"
          classes="text-blue-light text-2xl jost-semibold text-blue-light bg-gray-light block text-center"
      />
  </div>
</template>

<script>
import Heading from "@/components/HeadingComponent";
export default {
  name: 'MobileScreen',
  components: {
    Heading
  },
  props: {
    //
  },
  data() {
    return {
      //
    }
  },
  methods: {
    //
  },
}
</script>

<style scoped>
.mobile-screen-container {
  background-image: url("../../public/img/geeq-sign.png"), url("../../public/img/mobile_screen_bg.png");
  background-position: center 15%, center 85%;
  background-repeat: no-repeat;
  background-size: 80% auto;
}
</style>
